<div
	class="comments"
	[@preventInitialChildAnimations]
	*ngIf="item && item.comments"
>
	<div class="comment-wrapper" *ngFor="let comment of item.comments">
		<div
			class="comment"
			*ngIf="comment.user && comment.user.userName"
			[ngClass]="{
				ownComment:
					comment.user.userName ===
					userSessionService?.userDataValue?.userName
			}"
			[@commentEntryAnimation]
		>
			<app-user-avatar
				[displayType]="_userAvatarDisplayType"
				[userObject]="comment.user"
			></app-user-avatar>
			<span>{{ comment.message }}</span>

			<!--menu-->
			<div class="comment-options-container">
				<div
					*ngIf="
						comment.user.userName ===
						userSessionService?.userDataValue?.userName
					"
					class="comment-options"
					ngbDropdown
					placement="left"
					autoClose="true"
				>
					<button
						ngbDropdownToggle
						class="comment-options-menu-toggle"
					>
						<i class="fi fi-bs-menu-dots"></i>
					</button>
					<div class="comment-options-menu" ngbDropdownMenu>
						<button
							class="dropdown-item"
							(click)="deleteComment(comment._id)"
						>
							<i class="fi fi-ss-trash"></i> Delete
						</button>
					</div>
				</div>
				<div
					class="reply-link-inner"
					*ngIf="
						!comment.comments.length &&
						userSessionService.isLoggedInValue &&
						replyingToComment?._id !== comment._id
					"
				>
					<a
						href="javascript:void(0);"
						(click)="showReplyInput(comment)"
						>Reply</a
					>
				</div>
			</div>
		</div>

		<div
			class="comment-reply comment"
			*ngFor="let reply of comment.comments"
			[ngClass]="{
				ownComment:
					reply.user.userName ===
					userSessionService?.userDataValue?.userName
			}"
			[@commentEntryAnimation]
		>
			<app-user-avatar
				[displayType]="_userAvatarDisplayType"
				[userObject]="reply.user"
			></app-user-avatar>
			<span>{{ reply.message }}</span>
			<div class="comment-options-container">
				<div
					*ngIf="
						reply.user.userName ===
						userSessionService?.userDataValue?.userName
					"
					class="comment-options"
					ngbDropdown
					placement="left"
					autoClose="true"
				>
					<button
						ngbDropdownToggle
						class="comment-options-menu-toggle"
					>
						<i class="fi fi-bs-menu-dots"></i>
					</button>
					<div class="comment-options-menu" ngbDropdownMenu>
						<button
							class="dropdown-item"
							(click)="deleteReply(comment, reply._id)"
						>
							<i class="fi fi-ss-trash"></i> Delete comment
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="reply-link"
			*ngIf="
				comment.comments.length &&
				userSessionService.isLoggedInValue &&
				replyingToComment?._id !== comment._id
			"
		>
			<a href="javascript:void(0);" (click)="showReplyInput(comment)"
				>Reply to this thread</a
			>
		</div>

		<div
			class="reply-comments-input"
			*ngIf="comment._id === replyingToComment?._id"
		>
			<div class="input-group">
				<!--input field-->
				<input
					(keydown)="sendReply(comment, $event)"
					id="reply-comment-input"
					type="text"
					class="form-control"
					placeholder="{{
						'social.comments.input-placeholder' | translate
					}}"
					required="required"
					[disabled]="!sessionService.userDataValue"
				/>

				<!--speech bubble icon-->
				<span class="input-group-addon"
					><i class="lovegivr lov-speech-bubble"></i
				></span>
			</div>
			<p class="send-hint">
				{{ 'social.comments.send-with-enter-hint' | translate }}
			</p>
		</div>
	</div>

	<p
		class="no-comment-placeholder"
		*ngIf="!item.comments || !item.comments.length"
	>
		<i class="lovegivr lov-speech-bubble"></i>
		<span>{{ 'No comments yet...' }}</span>
	</p>
</div>
